.ant-descriptions {
  .ant-descriptions-header {
    .ant-descriptions-title {
      color: $card-text-color;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
  .ant-descriptions-view .ant-descriptions-row .ant-descriptions-item-label {
    color: $text-light-color-2;
    min-width: 170px;
  }

  .ant-descriptions-view .ant-descriptions-row .ant-descriptions-item-content {
    color: $color-white;
    font-weight: 600;
  }

  & > .ant-descriptions-view {
    border: unset;

    & > table > tbody {
      & > .ant-descriptions-row {
        border-color: transparent;

        .ant-descriptions-item-container > .ant-descriptions-item-label {
          border: none;
        }

        .ant-descriptions-item-label {
          background-color: transparent;
        }

        & > .ant-descriptions-item-label {
          background: $card-background;
        }
      }
    }
  }

  &.ant-descriptions-bordered > .ant-descriptions-view {
    border: 1px solid $card-border-color;

    & > table > tbody {
      & > .ant-descriptions-row {
        border-color: $card-border-color;
        & > .ant-descriptions-item-label,
        & > .ant-descriptions-item-content {
          border-color: $card-border-color;
        }

        & > .ant-descriptions-item-label {
          color: $text-light-color-2;
          background: $card-background;
        }
      }
    }
  }

  &.ant-descriptions-small {
    .ant-descriptions-header {
      margin-bottom: $margin-base;
    }

    .ant-descriptions-row {
      .ant-descriptions-item {
        padding-bottom: 0px;
      }

      .ant-descriptions-item-label,
      .ant-descriptions-item-content {
        padding: 0px;
      }
    }
  }
}
