.ant-radio-wrapper {
  .ant-radio-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    &::after {
      inset-block-start: unset;
      inset-inline-start: unset;
      margin-block-start: 0px;
      margin-inline-start: 0px;
    }
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #08964d;
    background-color: $text-light-color-2;

    &::after {
      transform: scale(1) !important;
      width: 8px;
      height: 8px;
    }
  }

  .ant-radio {
    align-self: unset;
  }

  &:hover {
    .ant-radio-inner {
      border-color: #08964d;
    }
  }

  .ant-radio-disabled .ant-radio-inner {
    background-color: $text-light-color-2;
    border-color: #d9d9d9;
  }
}

.ant-radio-button-wrapper-checked::before {
  background-color: #08964d !important;
}
