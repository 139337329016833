.ant-dropdown {
  .ant-dropdown-menu {
    background: $dropdown-background;
    border: 1px solid $dropdown-border-color;
    padding: $padding-sm 0;

    .ant-typography {
      color: $dropdown-text-color;
      font-weight: 500;
    }

    .ant-divider {
      border-color: transparentize($dropdown-border-color, 0.2);
    }

    .ant-dropdown-menu-item {
      color: $dropdown-text-color;
      font-size: $dropdown-font-size;
      padding: $dropdown-item-padding $dropdown-item-padding + 10;

      & > .anticon {
        font-size: $dropdown-font-size + 1;

        &:first-child {
          margin-right: $margin-sm;
        }
      }

      &-selected,
      &:hover {
        color: $select-color;
        background: $dropdown-active-background;
      }
    }
    .menu-submenu-selected {
      .ant-dropdown-menu-title-content > a {
        color: $select-color;
      }
    }
  }

  .ant-table-filter-dropdown {
    background: $dropdown-background;
    border: 1px solid $dropdown-border-color;

    .ant-dropdown-menu {
      background: transparent;
      border: none;
    }
    .ant-checkbox-wrapper {
      .ant-checkbox-inner {
        border: 1px solid #d9d9d9;
      }

      &-checked .ant-checkbox-inner {
        border-color: $select-color;
      }

      & + span {
        color: $text-light-color-2;
      }

      &:hover {
        .ant-checkbox-checked {
          .ant-checkbox-inner {
            background-color: $select-color;
          }
        }
      }
    }

    .ant-dropdown-menu-item-selected span {
      color: $select-color;
    }

    .ant-table-filter-dropdown-btns {
      border-top-color: transparentize($dropdown-border-color, 0.2);
      padding: $padding-sm;
    }
  }
}

.ant-dropdown-arrow {
  display: none !important;
}
