.ant-message {
  z-index: 10000;

  .ant-message-notice-wrapper {
    .ant-message-notice-content {
      background: $notification-background;
      border: 1px solid $notification-border-color;
      color: $notification-text-color;
      padding: $padding-md $padding-xl;
      min-width: $notification-min-width;
      max-width: $notification-max-width;
      border-radius: 0;

      .ant-message-info .anticon {
        color: $info-color;
      }

      .ant-message-warning .anticon {
        color: $warning-color;
      }

      .ant-message-error .anticon {
        color: $error-color;
      }

      .ant-message-success .anticon {
        color: $success-color;
      }
    }
  }
}

.ant-message .anticon {
  margin-right: $margin-xs;
}

.ant-notification-hook-holder,
.ant-notification-notice {
  width: 400px;
  background: $notification-background;
  border: 1px solid $notification-border-color;
  padding: $padding-lg $padding-xl;

  .ant-notification-notice-message {
    color: $notification-text-color;
  }
}

.anticon.ant-notification-notice-icon-info {
  color: $info-color;
}

.anticon.ant-notification-notice-icon-warning {
  color: $warning-color;
}

.anticon.ant-notification-notice-icon-error {
  color: $error-color;
}

.anticon.ant-notification-notice-icon-success {
  color: $success-color;
}

.ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 30px;
}

.ant-notification-notice-close {
  color: $notification-text-color;

  &:focus,
  &:hover {
    color: $select-color;
  }
}
