.ant-tree {
  background: transparent;

  .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
    color: $text-light-color-4;
  }

  .ant-tree-node-content-wrapper,
  .anticon {
    color: $text-light-color-2;
  }

  .ant-tree-node-content-wrapper:hover,
  .ant-tree-node-content-wrapper.ant-tree-node-selected {
    color: $select-color;
    background-color: transparent;

    .anticon {
      color: $select-color;
    }
  }
}

.ant-tree-select {
  .ant-select-selection-placeholder {
    color: transparentize($input-text-color, 0.5);
  }
}
.ant-tree .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
  color: $primary-green !important;
}
