.ant-steps {
  .ant-steps-item-container {
    .ant-steps-item-content > .ant-steps-item-title {
      color: $steps-text-color;
      font-size: 18px;
    }

    .ant-steps-item-content > .ant-steps-item-description {
      color: $steps-text-color;
      font-size: 13px;
    }

    .ant-steps-icon {
      color: $steps-text-color;
    }

    .ant-steps-item-icon {
      line-height: 35px;
    }
  }

  .ant-steps-item-icon,
  .ant-steps-item-content {
    display: inline-block;
    vertical-align: baseline;
  }

  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon,
  .ant-steps-item-custom.ant-steps-item-process
    .ant-steps-item-icon
    > .ant-steps-icon {
    color: $select-color;
  }

  .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon,
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title,
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    color: $steps-active-text-color;
  }

  ant-steps-item-description
    .ant-steps-item-error
    .ant-steps-item-icon
    > .ant-steps-icon,
  .ant-steps-item-error
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: $error-color;
  }

  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after,
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background: $steps-line-background;
  }

  .ant-steps-item-wait .ant-steps-item-icon {
    background: transparent;
    border-color: $steps-line-background;
  }

  .ant-steps-item-process .ant-steps-item-icon {
    background: $clickable-color;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    background: transparent;
  }
}

.ant-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: $text-light-color-5;
  top: -2px;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  border-color: $clickable-color;
}

.ant-steps
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: $text-light-color-5;
}

.ant-steps .ant-steps-item-icon {
  font-size: 16px;
}

.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: $clickable-color;
}

.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: $text-light-color-5;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  border-color: $clickable-color;

  & > .ant-steps-icon {
    top: -1px;
  }
}

.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: $clickable-color;
}

.ant-steps .ant-steps-item > .ant-steps-item-container[role='button']:hover {
  .ant-steps-item-icon {
    border-color: $clickable-color !important;

    .ant-steps-icon {
      color: $clickable-color !important;
    }
  }
  .ant-steps-item-title {
    color: $clickable-color !important;
  }
}
