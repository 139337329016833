.ant-btn {
  height: $button-height-md;
  min-width: $button-width-md;
  font-size: $button-font-size-md;
  font-weight: 600;
  padding: 0px $button-padding-md 0px $button-padding-md;
  text-transform: uppercase;
  border-width: 2px;
  color: $button-color;
  background-color: transparent;
  border-color: $button-color;
  letter-spacing: 0.4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;

  &:focus {
    color: $button-color;
    background-color: transparent;
    border-color: $button-color;
  }

  &:hover {
    color: $button-hover-color;
    background-color: transparent;
    border-color: $button-hover-color;
  }

  &-primary,
  &-primary:focus {
    color: $button-text-color;
    background-color: $button-color;
    border-color: $button-color;

    &:hover {
      color: $button-color;
      background-color: $button-hover-color !important;
      border-color: $button-hover-color;
    }
  }

  &-dangerous,
  &-dangerous:focus {
    color: $error-color;
    background-color: transparent;
    border-color: $error-color;

    &:hover {
      color: darken($error-color, 10);
      border-color: darken($error-color, 10);
    }

    &.ant-btn-primary {
      color: $button-color;
      background-color: $error-color;
      border-color: $error-color;

      &:hover,
      &:focus,
      &:active {
        background-color: darken($error-color, 10) !important;
        border-color: darken($error-color, 10) !important;
      }
    }
  }

  &-variant-outlined {
    &:hover,
    &:active {
      border-color: $clickable-color !important;
      color: $clickable-color !important;
      background: transparent !important;
    }
  }

  &.ant-btn-color-dangerous.ant-btn-variant-outlined {
    background-color: #0000;
    border-color: $error-color;
    color: $error-color;
    cursor: pointer;

    &:hover {
      border-color: #d02d4f !important;
      color: #d02d4f !important;
    }
  }

  &-color-dangerous.ant-btn-variant-solid:hover {
    background-color: #d02d4f !important;
    border-color: #d02d4f !important;
  }

  &-sm {
    height: $button-height-sm;
    min-width: $button-width-sm;
    font-size: $button-font-size-sm;
  }

  &-lg {
    height: $button-height-lg;
    min-width: $button-width-lg;
    font-size: $button-font-size-lg;
  }

  &[disabled]:hover,
  &[disabled] {
    opacity: 0.5;
    color: $button-color;
    background-color: transparent;
    border-color: $button-color;
  }

  &-primary[disabled]:hover,
  &-primary[disabled] {
    opacity: 0.5;
    color: $button-text-color;
    background-color: $button-color !important;
    border-color: $button-color;
  }

  &-dangerous[disabled]:hover,
  &-dangerous[disabled] {
    opacity: 0.5;
    color: $error-color;
    border-color: $error-color;
  }

  &-link[disabled]:hover,
  &-link[disabled] {
    border-color: $button-color;
    color: $button-color;
  }

  &-text,
  &-text:focus {
    border: none !important;

    &[disabled] {
      color: $text-light-color;
    }

    &[disabled]:hover {
      color: $text-light-color;
    }
  }
}

.ant-btn:not(:disabled):focus-visible {
  outline: none;
}

.ant-btn-variant-solid:active {
  background: $clickable-color !important;
  color: $text-light-color !important;
  border-color: $clickable-color !important;
}

.ant-btn:focus > .ant-btn-loading-icon,
.ant-btn:active > .ant-btn-loading-icon,
.ant-btn > .ant-btn-loading-icon {
  position: absolute;
  left: 6px;
}
.ant-switch.ant-switch-checked {
  background-color: #08964d;
  &:hover {
    background-color: #08964d !important;
  }
}

.ant-btn-color-link.ant-btn-variant-link {
  color: $text-light-color !important;

  &:hover {
    color: $clickable-color !important;
  }

  &[disabled]:hover {
    border-color: $button-color !important;
    color: $button-color !important;
  }
}

.ant-btn.ant-btn-circle.ant-btn {
  box-shadow: none;
}

.ant-btn-variant-text:not(:disabled):not(.ant-btn-disabled):hover {
  color: $clickable-color !important;
  background-color: transparent;
}
