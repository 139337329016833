.ant-modal {
  .ant-modal-content {
    background: $modal-background;
    border: 2px solid $modal-border-color;
    padding: $modal-padding;
    border-radius: 0;
    .ant-modal-close-x {
      display: block;
      height: 56px;
      line-height: 56px;
      width: 56px;
    }
  }

  .ant-modal-body {
    color: $modal-text-color;
    padding: math.div($modal-padding, 2) 0 math.div($modal-padding, 2) 0;

    .ant-modal-confirm-content {
      font-family: 'EurostileLT';
      white-space: pre-line;
    }
  }

  .ant-modal-header {
    padding: 0 0 math.div($modal-padding, 2) 0;
    background: transparent;
    border-bottom: none;
    margin: 0;

    .ant-modal-title {
      font-size: $modal-title-font-size;
      color: $modal-title-color;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  .ant-modal-footer {
    padding: math.div($modal-padding, 2) 0 0 0;
    border: none;

    button + button {
      margin-left: math.div($modal-padding, 2);
    }
  }

  .ant-modal-close {
    color: $modal-title-color;
    top: math.div($modal-padding, 2);
    right: math.div($modal-padding, 2);
    width: auto;
    height: auto;

    .anticon {
      font-size: $font-size-md;
    }

    &:focus,
    &:hover {
      background-color: transparent;
      color: $select-color;
    }
  }

  .ant-modal-confirm .ant-modal-body {
    padding: 0;
  }

  .ant-modal-confirm-body .ant-modal-confirm-content {
    color: $modal-title-color;
    font-size: $font-size-sm;
  }

  .ant-modal-confirm-body .ant-modal-confirm-title {
    font-size: $modal-title-font-size;
    color: $modal-title-color;
    font-weight: 600;
    text-transform: uppercase;
  }

  .ant-modal-confirm .ant-modal-confirm-btns {
    display: flex;
  }

  .ant-modal-confirm .ant-modal-confirm-btns button + button {
    margin-left: $margin-xs;
  }
}

.ant-modal-confirm .ant-modal-confirm-btns {
  display: flex;
  justify-content: flex-end;
}

.ant-message {
  font-family: var(--antd-font-family);
}

@media screen and (max-width: $mobile-screen) {
  .ant-modal-content {
    padding: $modal-padding-mobile;

    .ant-modal-title {
      font-size: $modal-title-font-size;
      // leave a space for close icon
      padding-right: 25px;
    }

    // change the position of close icon
    .ant-modal-close {
      top: 5px;
      right: 5px;
    }

    .ant-modal-header {
      padding-bottom: 0px;
    }
  }
}
