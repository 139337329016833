.ant-breadcrumb {
  color: $breadcrumb-color;
  margin-bottom: $margin-sm;
  font-size: $breadcrumb-font-size;
  font-weight: 600;
  letter-spacing: 0.5px;
  .ant-breadcrumb-link {
    display: inline-block;
  }

  .ant-breadcrumb-separator {
    position: relative;
    font-size: $breadcrumb-font-size * 1.4;
    color: inherit;

    margin: 0 $breadcrumb-font-size * 0.8;
  }
  ol {
    align-items: center;
  }

  li {
    &:last-child {
      color: $breadcrumb-last-color;

      .ant-breadcrumb-link {
        border-radius: 2px;
      }
    }

    &:not(:last-child) .ant-breadcrumb-link:hover {
      cursor: pointer;
      color: $select-color;
    }
  }
}

.ant-breadcrumb-separator {
  color: $breadcrumb-color;
}
