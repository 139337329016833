.ant-input,
.ant-input-number,
.ant {
  color: $input-text-color !important;
  background-color: $input-background;
  border-color: $input-border-color;
  border-radius: 0;

  &-focused,
  &:hover,
  &:focus,
  &:focus-within {
    border-color: $clickable-color;
    background-color: rgba(18, 27, 34, 0.3);
  }

  &::placeholder,
  input::placeholder {
    color: transparentize($input-text-color, 0.5);
  }
  .ant-input-number-input {
    color: $text-light-color-2;
    &::placeholder {
      color: transparentize($input-text-color, 0.5);
    }
  }

  &-outlined .ant-input-number-handler-wrap {
    background: transparent;
  }
}

.ant-input-number-outlined.ant-input-number-disabled {
  border-color: $border-color;
  opacity: 0.5;
}

.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input-affix-wrapper:hover,
.ant-form-item-has-error
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
  .ant-select-selector,
.ant-form-item-has-error .ant-input-number,
.ant-form-item-has-error .ant-picker {
  background-color: $input-background !important;
}

.ant-form-item {
  .ant-form-item-explain-error {
    color: $error-color;
  }
}

.ant-input-number-disabled,
.ant-input[disabled] {
  opacity: 0.5;
  color: $input-text-color;
  background-color: $input-background;
  border-color: $input-border-color;

  &:hover {
    border-color: $input-border-color;
  }
}

.ant-input-number-handler-wrap {
  border-color: $input-border-color;
  background-color: $input-background;

  .ant-input-number-handler.ant-input-number-handler-up,
  .ant-input-number-handler.ant-input-number-handler-down {
    border-color: $input-border-color;

    .anticon {
      color: $input-text-color !important;
    }

    &:hover .anticon {
      color: $select-color;
    }
  }
}

.ant-input-group-addon {
  background-color: $select-item-background;
  border-color: $select-border-color;
  color: $input-text-color;
}

.ant-input-affix-wrapper {
  border-color: $input-border-color;
  background-color: $input-background;

  &-focused,
  &:hover,
  &:focus {
    border-color: $clickable-color;
  }

  .anticon.ant-input-password-icon {
    color: $input-text-color;
    &:hover {
      color: $input-text-color;
    }
  }

  input {
    background-color: transparent;
  }
}

.ant-radio-wrapper {
  color: $input-text-color;

  .ant-radio-inner {
    background-color: $input-text-color;
  }

  .ant-radio-disabled {
    opacity: 0.5;
  }

  .ant-radio-disabled + span {
    color: transparentize($input-text-color, 0.5);
  }

  .ant-radio-checked .ant-radio-inner::after {
    background-color: $select-color;
  }
}

.ant-checkbox-wrapper {
  color: $input-text-color;

  .ant-checkbox-inner {
    background-color: $input-text-color;
  }

  .ant-checkbox-disabled {
    opacity: 0.5;
  }

  .ant-checkbox-disabled + span {
    color: transparentize($input-text-color, 0.5);
  }

  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: $input-text-color;
  }

  .ant-checkbox-checked {
    &:focus-visible {
      outline: none;
    }
    .ant-checkbox-inner {
      border-color: $primary-green;
      background-color: $select-color !important;
    }
  }
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $select-color !important;
}

.ant-radio-button-wrapper {
  background-color: $input-text-color;
  border-color: $input-text-color;

  &:hover {
    color: $select-color;
  }
}

.ant-checkbox .ant-checkbox-input:focus-visible + .ant-checkbox-inner {
  outline: none;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover,
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: $select-color;
  border-color: $select-color;
}

.ant-input-lg {
  font-size: 16px;
  padding: 7px 11px;
}

.PhoneInput {
  &--focus {
    .PhoneInputInput {
      outline: none;
    }
  }
  .PhoneInputCountry {
    border-right: none;
  }
}

.PhoneInputInput {
  border: 1px solid $border-color;
}

.react-tel-input {
  flex: 1 1 auto;
  display: flex;

  .country-list {
    background: $background-gradient-transparent;
    color: $text-light-color;

    .country {
      &.highlight,
      &:hover,
      &:focus {
        background-color: $background-color;
      }

      .dial-code {
        color: $text-light-color-4;
      }
    }
  }

  .flag-dropdown {
    &.open .selected-flag,
    .selected-flag:hover {
      background-color: $background-color;
      border-radius: 0px;
    }

    background-color: $background-color;
    border-color: $border-color;
  }

  > .ant-input {
    border-radius: 2px;
    border-color: $border-color;

    color: $text-light-color-2;
    background-color: rgba(18, 27, 34, 0.3);
  }
}

// Google Chrome autofill color fix
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  color: $input-text-color !important;
  -webkit-text-fill-color: $input-text-color !important;
  caret-color: $input-text-color;
  transition: background-color 50000s ease-in-out 0s;
}

// Safari input background color fix
.ant-form-item-has-error
  .ant-input:not(.ant-form-item-has-error .ant-input-disabled) {
  background-color: $input-background;
}

.ant-input-outlined:hover,
.ant-input-outlined:focus,
.ant-input-outlined:focus-within {
  background-color: rgba(18, 27, 34, 0.3);
  border-color: $clickable-color;
}

.ant-input-outlined.ant-input-disabled {
  border-color: $border-color !important;
}
